@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,100;1,200;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap');

/* ------------theme colors-------------- */
:root {
  --btn-color: #103178;
  --btn-hover-color: #103178;
  --link-color: #103178;
  --link-hover-color: #103178;
  --text-color: #103178;
}
/* ------------theme colors-------------- */

*{
  box-sizing: border-box !important;
}

body {
  margin: 0;
  padding: 0;
  font-family: 'Jost', sans-serif;
  color: var(--text-color) !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

input, textarea, select {
  background-color: #F0F2F5 !important;
  border-radius: 30px !important;
}

textarea {
  height: 6rem !important;
}

legend {
  font-size: large !important;
  font-weight: bold;
}


a {
  color: var(--link-color) !important;
}

input[type=submit], button {
  background-color: var(--btn-color) !important;
}
.navBar {
    min-height: 60px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* background-color: red; */
}

.navBar a {
    color: var(--link-color) !important;
    font-weight: 600;
    padding: 0.5rem;
    /* margin: 0.5rem; */
    /* margin-top: 2rem; */
    text-decoration: none !important;
    /* display: flex; */
}

.navBar a:hover {
    color: brown !important;
}

#drName {
    color: #5B6C8F !important;
    cursor: auto;
    float: right;
    margin-right: 1rem;
}
 .subCatCard {
    /* margin: 0.8rem; */
    margin-bottom: 0.8rem;
    padding: 0.3rem;
    border: 2px solid #A5CBE6;
    border-radius: 7px;
    box-shadow: 4px 4px 20px #A5CBE6 ;
    box-sizing: border-box !important;
}

.subCatCard > img {
	z-index:-1;
}

#subCatCardLink {
    text-decoration: none;
    color: var(--link-color) !important;
}

.subCatName {
    font-size: 18px;
    line-height: 28px;
    font-weight: 500;
}

#exploreBtn {
    color: white;
    border-radius: 50px;
    background-color: var(--btn-color) !important;
    font-weight: 500;
    font-size: 15px;
    float: right;
}

#exploreBtn:hover {
    background-color: var(--btn-hover-color) !important;
    color: white;
}

/* reactjs popup css */
.popup-content{
  margin:auto;
  background:#fff;
  width:80% !important;
  padding:5px;
  border:1px solid #d7d7d7
}

.modal1 {
    font-size: 12px;
}
.modal1 > .header1 {
  width: 100%;
  border-bottom: 1px solid gray;
  font-size: 18px;
  text-align: center;
  padding: 5px;
}
.modal1 > .content1 {
  width: 100%;
  padding: 10px 5px;
}

.modal1 > .actions1 {
  width: 100%;
  padding: 10px 5px;
  margin: auto;
  text-align: center;
}

.modal1 > .close1 {
  cursor: pointer;
  position: absolute;
  display: block;
  padding: 2px 5px;
  line-height: 20px;
  right: -10px;
  top: -10px;
  font-size: 24px;
  background: #ffffff !important;
  border-radius: 18px;
  border: 1px solid #cfcece;
}
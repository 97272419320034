.roundBtn {
  min-width: 100px;
  background-color: var(--btn-color) !important;
  border-radius: 30px !important;
}

.roundBtn:hover {
  background-color: #DB0A0A;
}

textarea {
  line-height: 5;
}

/* .centerBtn {
    width: auto;
    background-color: red;
} */

p, label {
  font-size: 14px !important;
}

@media screen and (max-width: 992px) {
  /* .App {
    margin-bottom: 4rem !important;
  } */
}
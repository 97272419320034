/* body {
    margin: 0 0 55px 0;
} */
.link__btn {
  color: #fff !important;
}

.nav {
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 55px;
    box-shadow: 0 0 3px rgba(0, 0, 0, 0.2);
    background-color: #ffffff;
    display: flex;
    overflow-x: auto;
	z-index: 1;
}

.nav__link {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    flex-grow: 1;
    min-width: 50px;
    overflow: hidden;
    white-space: nowrap;
    font-family: sans-serif;
    font-size: 13px;
    color: #444444;
    text-decoration: none;
    -webkit-tap-highlight-color: transparent;
    transition: background-color 0.1s ease-in-out;
}

.nav__link:hover {
    background-color: #eeeeee;
    cursor: pointer;
}

.nav__link--active {
    color: var(--link-color);
}

.nav__icon {
    font-size: 18px;
    color: var(--btn-color) !important;
}


/* side nav */

body {
	overflow-x: hidden;
}
.nav1 {
	height: 100%;
	width: 0;
	position: fixed;    
	z-index: 999;
	top: 0;
	left: 0;
	background-color: rgb(224, 224, 231);
	/* opacity: .9; */
	overflow-x: hidden;
	padding-top: 60px;
	transition: 0.4s;
}
.nav1 a {
	display: block;
	padding: 10px 30px;
	font-size: 20px;
	text-decoration: none;
	color: var(--link-color);
}
.nav1 a:hover {
	color: var(--link-hover-color) !important;
	transition: 0.2s;
}
.nav1 .close {
	position: absolute;
	top: 0;
	/* right: 22px; */
	right: -10px;
	margin-left: 50px;
	font-size: 30px
}
/* .slide a {
	color: #000;
	font-size: 36px;
} */
#content {
	padding: 20px;
	transition: margin-left 0.3s;
	overflow: hidden;
	width: 100%;
}
.bannerBox {
    /* background-image: url("../../assets/banner.jpeg"); */
    /* background-repeat: no-repeat; */
   display: flex;
   justify-content: center;
   /* flex-direction: column; */
   /* margin: 5rem; */
   
}

.bannerBox > img {
    max-width: 100%;
}

.bannerBox .roundBtn {
    width: 90px;
    background-color: var(--btn-color) !important;
    border-radius: 30px;
}

.bannerBox .roundBtn:hover {
    background-color: #DB0A0A;
}

.bannerBtn {
    position: relative;
    bottom: 5rem;
    left: 1.1rem;
}
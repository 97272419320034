/* .headerContainer {
    border-bottom: 1px solid lightgrey;
} */

#searchBar {
  margin-left: 1rem;
  padding: 15px;
  background-color: #f0f2f5;
  border-radius: 2rem;
  font-size: 18px;
  max-width: 520px;
}

#searchBar::placeholder {
  color: #5b6c8f;
  font-weight: 500;
  font-size: 16px;
}

.profileIcon {
  cursor: pointer;
}

.desktopOnly {
  display: block !important;
}

.mobileOnly {
  display: none !important;
}

/* mobile screens */
@media only screen and (max-width: 800px) {
  /* #searchBar{
        display: none;
    } */

  .desktopOnly {
    display: none !important;
  }

  .mobileOnly {
    display: block !important;
  }
  .header__label.text-center {
    text-align: right !important;
  }
}

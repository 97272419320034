.contactDetails {
    padding: 0.2rem 2.2rem;
    clear: both;
}

.contactDetails > div > h4, .contactDetails > div > h5 {
    color: #0E3178;
    font-weight: bold;
    padding-top: 1rem;
}

.contactDetails > div > h6 {
    color: #9BABCD;
    /* font-weight: bold; */
}
.modal-popup .modal-content{
  background-color: transparent !important;
}

.modal-popup .close_btn{
  position: relative;
  top: 14px;
  left: 9px;
  display: block;
  margin-left: auto;
  border-radius: 50%;
  border: 2px solid #fef9f9;
  /* padding: 9px; */
  background-color: #000000;
  /* background-size: 9px; */
  width: 24px;
  height: 24px;
  color: white;
  font-size: 12px;
  opacity: 1;
}

.modal-popup .pop-img{
	border-radius: 10px;
    border: 1px solid white;
}